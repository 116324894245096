import React, { useState } from "react";
import classNames from "classnames";

import css from "./style.module.css";
import centralTextCss from "../CentralText/style.module.scss";
import middleActionCss from "../MiddleActionBar/style.module.scss";
import { Modal } from "../../common/Modal/Modal";

export const BottonCardPanel = ({ isDev }: { isDev: boolean }) => {
  const [mediaModalOpened, setMediaModalOpened] = useState(false);
  const [workshopModalOpened, setWorkshopModalOpened] = useState(false);

  return (
    <div className="d-flex gap-3 flex-wrap">
      <div className={css["card"]}>
        <div className={classNames(css["title"], "text-uppercase")}>
          <h2>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.medien.kita.bayern"
            >
              {"Medien\nEcke"}
            </a>
          </h2>
        </div>

        <Modal
          show={mediaModalOpened}
          onClose={() => setMediaModalOpened(false)}
          title="Erleben. Staunen. Mitmachen."
        >
          <p>
            Du möchtest erfahren, wie digitale Medien pädagogisch sinnvoll im
            Kita-Alltag eingesetzt werden können? Du wünschst dir Orientierung
            und Unterstützung, um dich auf die neuen Bildungsaufgaben und
            digitalen Entwicklungen in der Kindertagesbetreuung einzulassen? Du
            suchst Inspirationen, wie du Medienbildung mit Kindern gestalten
            kannst?
          </p>
          <p>
            Besuche unsere PIXELWERKSTATT in Amberg: Wir unterstützen dich mit
            medienpädagogischen, methodischen und technischen Tipps. Probiere
            verschiedenes Equipment aus, hole dir Praxisanregungen und nimm an
            Workshops, Fortbildungen und Tagungen teil.
          </p>
          <p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.pixelwerkstatt.kita.bayern"
            >
              www.pixelwerkstatt.kita.bayern
            </a>
          </p>
          <p>
            {/* <a
              target="_blank"
              rel="noreferrer"
              href={`https://www.pixelwerkstatt${
                isDev ? "-dev" : ""
              }.kita.bayern`}>
              Hier
            </a>{" "} */}
            {/* findest du Medien, Ideen, Anleitungen und Tipps zum Stöbern und zum
            Download. */}
          </p>
        </Modal>

        <div className={css["text"]}>
          Du suchst einen Ort, der Bildungsmaterialien für den Kita-Bereich
          bereithält? Du möchtest deinen Arbeitsalltag mit freien und
          kostenlosen Praxisanregungen verbessern? Du willst wissen, wo du
          qualitativ hochwertige und qualitätsgeprüfte Informationen finden
          kannst?{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.medien.kita.bayern"
          >
            www.medien.kita.bayern
          </a>
          <br />
          <div className="d-flex justify-content-center">
            <button
              onClick={() => setWorkshopModalOpened(true)}
              aria-label="Klick hier"
              className={centralTextCss["button"]}
            >
              Mehr Infos
            </button>
          </div>
        </div>

        <img
          className={classNames(css["left-bubble-arrow"])}
          alt="hier bekommst du alles zeiger"
          src="/images/bottom-section/FM_Arrow.svg"
        />
        <a
          tabIndex={0}
          target="_blank"
          rel="noreferrer"
          href="https://www.medien.kita.bayern"
          className={classNames(css["left-bubble"], middleActionCss["bubble"])}
        >
          <div className={css["left-bubble-text"]}>
            Literatur. Ideen. <br /> Anleitungen.
          </div>
        </a>
      </div>

      <div className={css["card"]}>
        <div className={classNames(css["title"], "text-uppercase")}>
          <h2>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.pixelwerkstatt.kita.bayern"
            >
              {"Pixel\nWerkstatt"}
            </a>
          </h2>
        </div>

        <Modal
          show={workshopModalOpened}
          onClose={() => setWorkshopModalOpened(false)}
          title="Literatur. Ideen. Anleitungen."
        >
          <p>
            <video src="/video/erklaervideo-medien.webm" controls></video>
          </p>
          <p>
            Du suchst einen Ort, der Bildungsmaterialien für den Kita-Bereich
            bereithält? Du möchtest deinen Arbeitsalltag mit freien und
            kostenlosen Praxisanregungen verbessern? Du willst wissen, wo du
            qualitativ hochwertige und qualitätsgeprüfte Informationen finden
            kannst?
          </p>
          <p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.medien.kita.bayern"
            >
              Hier
            </a>{" "}
            findest du Medien, Ideen, Anleitungen und Tipps zum Stöbern und zum
            Download.
          </p>
        </Modal>

        <div className={css["text"]}>
          Du möchtest erfahren, wie digitale Medien pädagogisch sinnvoll im
          Kita-Alltag eingesetzt werden können? Du wünschst dir Orientierung und
          Unterstützung, um dich auf die neuen Bildungsaufgaben und digitalen
          Entwicklungen in der Kindertagesbetreuung einzulassen? Besuche alleine
          oder gemeinsam mit deinem Team unsere PIXELWERKSTATT in Amberg.
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://www.pixelwerkstatt${
              isDev ? "-dev" : ""
            }.kita.bayern`}
          >
            www.pixelwerkstatt.kita.bayern
          </a>
          <br />
          <p>&nbsp;</p>
          <div className="d-flex justify-content-center">
            <button
              onClick={() => setMediaModalOpened(true)}
              aria-label="Klick hier"
              className={centralTextCss["button"]}
            >
              Mehr Infos
            </button>
          </div>
        </div>
        <img
          className={classNames(css["right-bubble-arrow"])}
          alt="hier entstehen coole sachen"
          src="/images/bottom-section/LE_Arrow.svg"
        />

        <a
          tabIndex={0}
          target="_blank"
          rel="noreferrer"
          href="https://www.pixelwerkstatt.kita.bayern"
          className={classNames(css["right-bubble"], middleActionCss["bubble"])}
        >
          <div className={css["right-bubble-text"]}>
            Erleben. <br /> Staunen. <br /> Mitmachen.
          </div>
        </a>
      </div>
    </div>
  );
};
